import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url'


const userStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 로그인
        login: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                //axios.post(state.host + '/api/User/Login', params).then(res => {
                axios.post(SERVER.URL + '/api/User/Login', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                    /*
                    var resData = res.data;
                    if(res.data.responseCode == "E-Lo-01"){
                        alert("등록되지 않은 이메일입니다.");
                        return false;
                    }else if(res.data.responseCode == "E-Lo-02"){
                        alert("비밀번호가 일치하지 않습니다.");
                        return false;
                    }else if(res.data.responseCode == "Lo-01"){
                        resData.email = params.email;
                        resData.userName = res.data.userName;
                        resData.userRole = res.data.role;
                        //resData.userRole = 0;
                        commit('loginToken', resData);
                        resolve(res);
                    }
                    */
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 로그아웃
        logout: ({commit}) => { 
            commit('logoutUser');
            //this.$router.push('/login')
        },

        // 사용자 리스트
        list: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User?searchtype=' + params.searchtype + '&searchtext=' + params.searchtext,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 사용자 정보
        info: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/User/'+params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 사용자 등록
        insert: ({commit}, params) => {
            console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/User', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 사용자 수정
        update: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/User/'+params.userid, params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 비밀번호 변경
        updatePw: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/User/UpdatePassword', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 디바이스 정보 다시 가져오기
        refreshDevice: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/User/RefreshDevice/'+params.userid, params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },
        
    }
}

export default userStore
