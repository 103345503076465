import axios from "axios";
import SERVER from '@/API/url'

const tokenStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 토큰 가져오기
        token: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/ServiceCode/GetWebPubSubKey?hubs='+params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default tokenStore