import { createStore } from 'vuex';
import axios from 'axios'
import VueCookies from 'vue-cookies';
import adminStore from '@/store/modules/adminStore.js'
import deviceSettingStore from '@/store/modules/deviceSettingStore.js'
import deviceStore from '@/store/modules/deviceStore.js'
import locationStore from '@/store/modules/locationStore.js'
import spaceStore from '@/store/modules/spaceStore.js'
import statisticsStore from '@/store/modules/statisticsStore.js'
import statusStore from '@/store/modules/statusStore.js'
import tokenStore from '@/store/modules/tokenStore.js'
import userStore from '@/store/modules/userStore.js'

export default new createStore({
    state: {
        layout: 'web',
        layout_style: 'full',
        user:{
            loggedIn: false,
            data: null
        }
    },
    mutations: {
        setLoggedIn(state, value){
            state.user.loggedIn = value;
        },
        loginToken (state, data) {
            state.user.data = data;
            VueCookies.set('silvercms_accessToken', data.accessToken);
            VueCookies.set('silvercms_refreshToken', data.refreshToken);
            VueCookies.set('silvercms_userId', data.userId);
            VueCookies.set('silvercms_loginID', data.loginID);
            VueCookies.set('silvercms_userName', data.userName);
            VueCookies.set('silvercms_guardian', data.guardian);
            VueCookies.set('silvercms_role', data.role);

            //console.log(state);
        },
        resetUserInfo(state, data){
            if(state.user.data == null){
                state.user.data = data;
                VueCookies.set('silvercms_accessToken', data.accessToken);
                VueCookies.set('silvercms_refreshToken', data.refreshToken);
                VueCookies.set('silvercms_userId', data.userId);
                VueCookies.set('silvercms_loginID', data.loginID);
                VueCookies.set('silvercms_userName', data.userName);
                VueCookies.set('silvercms_guardian', data.guardian);
                VueCookies.set('silvercms_role', data.role);
            }
        },
        refreshToken(state, data){
            if(state.user.data == null){
                VueCookies.set('silvercms_accessToken', data.accessToken);
                VueCookies.set('silvercms_refreshToken', data.refreshToken);
            }
        },
        logoutUser (state) {
            state.user.loggedIn = false;
            state.user.data = null;
            VueCookies.remove('silvercms_accessToken');
            VueCookies.remove('silvercms_refreshToken');
            VueCookies.remove('silvercms_userId');
            VueCookies.remove('silvercms_loginID');
            VueCookies.remove('silvercms_userName');
            VueCookies.remove('silvercms_guardian');
            VueCookies.remove('silvercms_role');

            //console.log(state);
        },
    },
    getters: {
        user(state){
            return state.user
        }
    },
    actions: {},
    modules: {
        adminStore: adminStore,
        deviceSettingStore: deviceSettingStore,
        deviceStore: deviceStore,
        locationStore: locationStore,
        spaceStore: spaceStore,
        statisticsStore: statisticsStore,
        statusStore: statusStore,
        tokenStore: tokenStore,
        userStore: userStore,
    },
});
