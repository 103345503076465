export default {
    //URL: 'https://localhost:7193',
    //URL: 'http://192.168.1.45:7010',
    //URL: 'https://silverapi.aidsoft.io',
    URL: 'https://api.aiotcare.co.kr',
    ROUTES: {
        register: '/',
    },
    HEADERS: {
        'ApiKey' : '84be4c9byjni8450t4hm5878f6932713'
    },
}