<template>
    <div class="topbar">
        <!-- LOGO -->
        <div class="topbar-left">
            <div class="text-center">
                <!-- pc 로고 -->
                <router-link to="/" class="logo"><img src="@/assets/images/logo.png" height="50"></router-link>
                <!-- <a href="/" class="logo">FIRST CARE</a> -->
                
                <!-- mob 로고 -->
                <router-link to="/" class="logo-sm"><img src="@/assets/images/logo.png" height="36"></router-link>
            </div>
        </div>

        <!-- 사이드 메뉴 버튼 -->
        <div class="navbar navbar-default" role="navigation">
            <div class="container">
                <div class="">
                    <div class="pull-left">
                        <button type="button" class="button-menu-mobile open-left waves-effect waves-light" @click="toggleEnlarged">
                            <i class="ion-navicon"></i>
                        </button>
                        <span class="clearfix"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="left side-menu">
            <div class="sidebar-inner slimscrollleft">
                <div class="user-details">
                    <div class="user-info">
                        <div class="dropdown">
                            <a href="#" class="dropdown-toggle">{{ userName }}</a>
                        </div>

                        <p class="text-muted m-0">{{ loginID }}</p>
                    </div>
                </div>

                <div id="sidebar-menu">
                    <ul>
                        <li>
                            <router-link to="/dashboard" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M1 2C1 1.70277 1.10723 1.43616 1.2705 1.25475C1.43064 1.07682 1.62386 1 1.8 1H7.1V17H1.8C1.62386 17 1.43064 16.9232 1.2705 16.7452C1.10723 16.5638 1 16.2972 1 16V2ZM10.9 1H16.2C16.3761 1 16.5694 1.07681 16.7295 1.25475C16.8928 1.43616 17 1.70277 17 2V7.1H10.9V1ZM10.9 10.9H17V16C17 16.2972 16.8928 16.5638 16.7295 16.7452C16.5694 16.9232 16.3761 17 16.2 17H10.9V10.9Z"
                                        stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <span> 대시보드 </span>
                            </router-link>
                        </li>

                       <li>
                            <router-link to="/memberStatus">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    >
                                    <rect x="8" y="3" width="2" height="6" rx="1" fill="white" />
                                    <rect x="8" y="10" width="2" height="2" rx="1" fill="white" />
                                    <path
                                        d="M0.84375 13.9551C0.84375 14.4025 1.20937 14.764 1.66193 14.764H16.3381C16.7906 14.764 17.1562 14.4025 17.1562 13.9551V8.06461C17.1562 3.61011 13.5051 0 9 0C4.49489 0 0.84375 3.61011 0.84375 8.06461V13.9551ZM2.68466 8.06461C2.68466 4.61629 5.5125 1.82022 9 1.82022C12.4875 1.82022 15.3153 4.61629 15.3153 8.06461V12.9438H9.63636C9.46875 12.9438 9.52131 12.9438 9.38068 12.9438H8.25568C8.11506 12.9438 8.25568 12.9438 8 12.9438H2.68466V8.06461ZM17.1818 16.382H0.818182C0.365625 16.382 0 16.7435 0 17.191V17.7978C0 17.909 0.0920455 18 0.204545 18H17.7955C17.908 18 18 17.909 18 17.7978V17.191C18 16.7435 17.6344 16.382 17.1818 16.382Z"
                                        fill="white" />
                                </svg>
                                <span> 회원 상황 관리 </span>
                            </router-link>
                        </li>

                         <li>
                            <router-link to="/device">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <rect x="3" y="4" width="2" height="1" fill="white" />
                                    <rect x="13" y="4" width="2" height="1" fill="white" />
                                    <rect x="3" y="6" width="2" height="1" fill="white" />
                                    <rect x="13" y="6" width="2" height="1" fill="white" />
                                    <path
                                        d="M1 2C1 1.70277 1.10723 1.43616 1.2705 1.25475C1.43064 1.07682 1.62386 1 1.8 1H7V17H1.8C1.62386 17 1.43064 16.9232 1.2705 16.7452C1.10723 16.5638 1 16.2972 1 16V2ZM16.7319 1.25015C16.8927 1.4311 17 1.69924 17 2V16C17 16.3008 16.8927 16.5689 16.7319 16.7498C16.5745 16.927 16.3879 17 16.2222 17H11V1H16.2222C16.3879 1 16.5745 1.07304 16.7319 1.25015Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                                <span> 디바이스 관리 </span>
                            </router-link>
                        </li>

                        <li>
                            <router-link to="/user/memberList" :class="{ 'active': $route.path.startsWith('/user') }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"
                                    fill="none">
                                    <path
                                        d="M9 1.8C8.17488 1.8 7.38356 2.13187 6.80011 2.72261C6.21667 3.31335 5.88889 4.11457 5.88889 4.95C5.88889 5.78543 6.21667 6.58665 6.80011 7.17739C7.38356 7.76813 8.17488 8.1 9 8.1C9.82512 8.1 10.6164 7.76813 11.1999 7.17739C11.7833 6.58665 12.1111 5.78543 12.1111 4.95C12.1111 4.11457 11.7833 3.31335 11.1999 2.72261C10.6164 2.13187 9.82512 1.8 9 1.8ZM4.11111 4.95C4.11111 3.63718 4.62619 2.37813 5.54303 1.44982C6.45988 0.521516 7.70339 0 9 0C10.2966 0 11.5401 0.521516 12.457 1.44982C13.3738 2.37813 13.8889 3.63718 13.8889 4.95C13.8889 6.26282 13.3738 7.52187 12.457 8.45018C11.5401 9.37848 10.2966 9.9 9 9.9C7.70339 9.9 6.45988 9.37848 5.54303 8.45018C4.62619 7.52187 4.11111 6.26282 4.11111 4.95ZM0 15.3016C0 14.1081 0.468253 12.9635 1.30175 12.1196C2.13524 11.2757 3.2657 10.8016 4.44444 10.8016H13.5556C14.7343 10.8016 15.8648 11.2757 16.6983 12.1196C17.5317 12.9635 18 14.1081 18 15.3016V18.0016H0V15.3016ZM4.44444 12.6016C3.7372 12.6016 3.05892 12.886 2.55883 13.3924C2.05873 13.8987 1.77778 14.5855 1.77778 15.3016V16.2016H16.2222V15.3016C16.2222 14.5855 15.9413 13.8987 15.4412 13.3924C14.9411 12.886 14.2628 12.6016 13.5556 12.6016H4.44444Z"
                                        fill="white" />
                                </svg>
                                <span> 사용자 관리 </span>
                                <span class="pull-right"><i class="mdi mdi-plus"></i></span>
                            </router-link>
                            <ul class="list-unstyled" @show="isSubMeunActive">
                                <li><router-link to="/user/memberList" :class="{ 'active': $route.path === '/memberList' }">회원 관리</router-link></li>
                                <li v-if="role == 1"><router-link to="/user/controllerList" :class="{ 'active': $route.path === '/controllerList' }">관제사 관리</router-link></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="clearfix"></div>

            </div>

            <!-- 로그아웃 -->
            <a href="javascript:;" @click="logout();" class="logout">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path  d="M1 1H12C12.7956 1 13.5587 1.26866 14.1213 1.74688C14.6839 2.2251 15 2.8737 15 3.55V15.45C15 15.6754 14.8946 15.8916 14.7071 16.051C14.5196 16.2104 14.2652 16.3 14 16.3H11" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M1 1L6.3116 2.30469C6.7876 2.42151 7.21594 2.73719 7.52376 3.19803C7.83158 3.65887 7.99987 4.23642 8 4.83242V10.9867V17.141C7.99994 17.2682 7.97691 17.3937 7.93259 17.5085C7.88826 17.6234 7.82374 17.7248 7.74366 17.8053C7.66359 17.8859 7.56995 17.9436 7.46951 17.9744C7.36907 18.0052 7.26432 18.0082 7.1628 17.9833L2.1256 16.7474C1.80811 16.6695 1.52243 16.4589 1.3172 16.1514C1.11197 15.8439 0.999872 15.4586 1 15.0611V1Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18 9.50469H10M10 9.50469L12.3529 7.80469M10 9.50469L12.3529 11.2047" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>로그아웃</span> 
            </a>
        </div>
    </div>
</template>

<script>

import { webPubSubService } from '@/API/webPubSubService';

export default {
    data() {
        return {
            userName: "",
            loginID: "",
            role: 0,
        };
    },
    watch: {
        $route(to, from){
            var user = this.$store.getters['user'];
            if(user.loggedIn){
                this.userName = user.data.userName;
                this.loginID = user.data.loginID;
                this.role = Number(user.data.role);
            }
        }
    },
    mounted() {

        var user = this.$store.getters['user'];
        if(user.loggedIn){
            this.userName = user.data.userName;
            this.loginID = user.data.loginID;
            this.role = Number(user.data.role);
        }

        // 초기에도 윈도우 폭에 따라 enlarged 클래스 추가 또는 제거
        this.handleWindowResize();
        // 윈도우 리사이즈 이벤트에 대한 리스너 등록
        window.addEventListener('resize', this.handleWindowResize);
    },
    beforeDestroy() {
        // 컴포넌트가 파괴되기 전에 윈도우 리사이즈 이벤트 리스너 제거
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {

        // 로그아웃
        logout(){
            if(webPubSubService.isConnected()){
                webPubSubService.disconnect();
            }
            this.$store.commit('logoutUser');
            this.$router.push("/login")
        },

        // #wrapper에 클래스 토글하는 메서드 추가
        toggleEnlarged() {
            var wrapper = document.getElementById('wrapper');
            if (wrapper) {
                wrapper.classList.toggle('enlarged');
            }
        },

        alertMessage(msg) {
            this.$swal({
                text: msg,
                padding: '3em',
                fontsize: '16px',
                color: '#000',
            });
        },

        // 윈도우 리사이즈 이벤트에 대한 핸들러
        handleWindowResize() {
            // enlarged 클래스를 윈도우 폭에 따라 추가 또는 제거
            var wrapper = document.getElementById('wrapper');
            if (wrapper) {
                if (window.innerWidth <= 620) {
                    wrapper.classList.add('enlarged');
                } else {
                    wrapper.classList.remove('enlarged');
                }
            }
        },
    }
}
</script>



