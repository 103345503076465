import { createRouter, createWebHistory } from 'vue-router';
import DefaultLayout from '@/components/layout/defaultLayout.vue';
import LoginLayout from '@/components/layout/loginLayout.vue';
import VueCookies from 'vue-cookies';
import VueJwtDecode from 'vue-jwt-decode';
import store from '../store';
import { webPubSubService } from '@/API/webPubSubService';

const routes = [
    
    // home
    {
        path: '/',
        redirect: '/dashboard',
        meta: {
            authRequired: true,
            title: "대시보드",
        },
    }, 

    //대시보드
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/home/dashboard'),
        meta: {
            authRequired: true,
            title: "대시보드",
        },
    },

     //회원 상황 관리
     {
        path: '/memberStatus',
        name: 'memberStatus',
        component: () => import('../views/memberStatus/list'),
        meta: {
            authRequired: true,
            title: "회원 상황 관리",
        },
    },

    //디바이스 관리
    {
        path: '/device',
        name: 'device',
        component: () => import('../views/device/list'),
        meta: {
            authRequired: true,
            title: "디바이스 관리",
        },
    },

    //사용자 관리
    {
        path: '/user',
        name: 'memberList',
        meta: {
            authRequired: true,
            title: "사용자 관리",
        },
        children: [{
                path: '/user/memberList',
                name: 'memberList',
                meta: {
                    authRequired: true,
                    title: "회원 관리",
                },
                component: () => import('../views/user/memberList'),
            },
            {
                path: '/user/controllerList',
                name: 'controllerList',
                meta: {
                    authRequired: true,
                    title: "관제사 관리",
                },
                component: () => import('../views/user/controllerList'),
            }
        ]
    },

    // 로그인
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/account/login'),
        meta: {
            title: "로그인",
            layout: LoginLayout, // 헤더 없는 레이아웃 적용
        },        
    },
    
];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    linkActiveClass: "active",
    linkExactActiveClass: 'sub-active', // 서브메뉴 활성화 클래스
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});


router.beforeEach((to, from, next) => {
    /*
     * to: 이동할 url 정보가 담긴 라우터 객체
     * from: 현재 url 정보가 담긴 라우터 객체
     * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
     * next() 가 호출되기 전까지 화면 전환되지 않음
     */

    // to.meta.layout이 설정되어 있다면 해당 레이아웃을 사용
    var layoutComponent = to.meta.layout || DefaultLayout;
    // layoutComponent를 동적으로 설정
    router.options.routes[1].component = layoutComponent;

    var token = VueCookies.get('silvercms_accessToken');
    var isVerified = false;

    if(token !== null){
        let decoded = VueJwtDecode.decode(token)
        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        
        if(expireDate > today){
            isVerified = true;
        }
    }

    if(to.meta.title != undefined){
        document.title = "퍼스트케어 CMS | " + to.meta.title;
    }else{
        document.title = "퍼스트케어 CMS";
    }

    if (store.state.user.loggedIn){

        if(!webPubSubService.isConnected()){

            var hubName = "admin";
            
            store.dispatch('tokenStore/token', hubName)
            .then((res) => {
                //console.log(res.data);
    
                if(res.data){
                    var token = res.data;
                    webPubSubService.connect(token);
                }
                
                return res;
            })
            .catch(({message}) => (alert(message)));
        }
        
        return next();

    }else{
        if (token !== null){
            //accessToken이 있을 경우 진행
            var userInfo = {
                "accessToken": VueCookies.get('silvercms_accessToken'),
                "refreshToken": VueCookies.get('silvercms_refreshToken'),
                "userId": VueCookies.get('silvercms_userId'),
                "loginID": VueCookies.get('silvercms_loginID'),
                "userName": VueCookies.get('silvercms_userName'),
                "guardian": VueCookies.get('silvercms_guardian'),
                "role": VueCookies.get('silvercms_role'),
            };
    
            if(isVerified){
                
                // accessToken 인증 시
                store.commit('setLoggedIn', true);
                store.commit('resetUserInfo', userInfo);

                if(!webPubSubService.isConnected()){

                    var hubName = "admin";
                    
                    store.dispatch('tokenStore/token', hubName)
                    .then((res) => {
                        //console.log(res.data);
            
                        if(res.data){
                            var token = res.data;
                            webPubSubService.connect(token);
                        }
                        
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }

                return next();

            }else{

                // accessToken 만료 시 - refreshToken으로 재발급
                var refreshToken = VueCookies.get('silvercms_refreshToken');
                var userId = VueCookies.get('silvercms_userId');

                if(refreshToken != null && userId != null){
                    var params = {
                        "refreshToken": refreshToken,
                        "userId": userId,
                    };
    
                    store.dispatch('adminStore/refreshToken', params)
                    .then((res) => {
                        //console.log(res.data);
                        if(res.data.success){
                        
                            // refreshToken & accessToken 재발급 후 페이지 이동
                            store.commit('refreshToken', res.data.data);

                            if(!webPubSubService.isConnected()){

                                var hubName = "admin";
                                
                                store.dispatch('tokenStore/token', hubName)
                                .then((res) => {
                                    //console.log(res.data);
                        
                                    if(res.data){
                                        var token = res.data;
                                        webPubSubService.connect(token);
                                    }
                                    
                                    return res;
                                })
                                .catch(({message}) => (alert(message)));
                            }

                            next();
    
                        }else{
                            // refreshToken 정보 안맞을 경우 로그아웃 진행
                            store.commit('logoutUser');

                            const authRequired = to.matched.some((route) => route.meta.authRequired)

                            if(!authRequired){
                                return next();
                            }else{
                                alert('로그인이 필요한 페이지입니다!');
                                next("/login");
                            }
                        }
                        return res;
                    })
                    .catch(({message}) => (alert(message)));
                }else{

                    // refreshToken 정보 없을 경우 로그아웃 진행
                    store.commit('logoutUser');

                    const authRequired = to.matched.some((route) => route.meta.authRequired)

                    if(!authRequired){
                        return next();
                    }else{
                        alert('로그인이 필요한 페이지입니다!');
                        next("/login");
                    }
                }

            }
            
        }else{
            
            if(!isVerified && token !== null){
                // accessToken이 종료되었을 경우 로그아웃 진행
                store.commit('logoutUser')
            }
            const authRequired = to.matched.some((route) => route.meta.authRequired)

            if(!authRequired){
                return next();
            }else{
                alert('로그인이 필요한 페이지입니다!');
                next("/login");
            }
        }
    }
});

export default router;
