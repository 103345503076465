import axios from "axios";
import SERVER from '@/API/url'

const deviceStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 리스트
        list: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement?devicetype='+params.devicetype+'&searchtext='+params.searchtext+'&devicestatus='+params.devicestatus,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 디바이스 정보
        deviceInfo: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement/GetDeviceSettingInfo/'+params.deviceid+'/'+params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // status reporting log
        reportLog: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement/GetStatusReportingLog/'+params.deviceid+'?startdate='+params.startdate+'&enddate='+params.enddate+'&pagingno='+params.pagingno+'&rows='+params.rows,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 움직임 graph
        graphLog: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement/GetReportLog/'+params.deviceid+'?startdate='+params.startdate+'&enddate='+params.enddate+'&code='+params.code,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // Sports Para graph
        sportsPara: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement/GetSportsPara/'+params.deviceid+'?selecteddate='+params.selecteddate,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 스마트 플러그 그래프
        getCurrentVoltage: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/DeviceManagement/GetCurrentVoltage/'+params.deviceid+'/'+params.type,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default deviceStore