import axios from "axios";
import SERVER from '@/API/url'

const statusStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        //  리스트
        list: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/StatusManagement?startdate='+params.startdate+'&enddate='+params.enddate+'&searchtext='+params.searchtext+'&level='+params.level+'&docity='+params.docity,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 회원별 상황 정보
        getDetail: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/StatusManagement/GetDetail/'+params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 조치 내역 저장
        save: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/StatusManagement', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 상황 내역
        statuslist: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/StatusManagement/statuslist/' + params.userid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 알람 해제
        clear: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/StatusManagement/Clear/' + params.userid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default statusStore