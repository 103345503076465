import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store'
import VueSweetalert2 from 'vue-sweetalert2';
import mitt from "mitt"; // 컴포넌트간 통신

// https://apexcharts.com/vue-chart-demos/line-charts/basic/
import VueApexCharts from "vue3-apexcharts";

//https://primevue.org/
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';


// 로컬 css
import './assets/css/bootstrap.min.css';
import './assets/css/morris.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/icons.css';


import "primevue/resources/themes/lara-light-teal/theme.css";
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';



// sweetalert2
// https://sweetalert2.github.io/
import 'sweetalert2/dist/sweetalert2.min.css';
// 알랏 공통 옵션
const options = {
    confirmButtonColor: '#80DB39',
    cancelButtonColor: '#FFF',
    confirmButtonText: '확인',
    cancelButtonText: '취소'
  };



const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter

app.use(router).use(store).use(VueApexCharts).use(PrimeVue).use(VueSweetalert2,options).mount('#app')

app.component('Dialog', Dialog);