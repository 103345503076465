import axios from "axios";
import VueCookies from 'vue-cookies';
import SERVER from '@/API/url'


const adminStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // 로그인
        login: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Admin/Login', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // refreshToken
        refreshToken: ({commit}, params) => {
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Admin/refresh_token', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 로그아웃
        logout: ({commit}) => { 
            commit('logoutUser');
        },
        
        // 관제사 리스트
        list: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Admin?searchtype=' + params.searchtype + '&searchtext=' + params.searchtext,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 관제사 정보
        info: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.get(SERVER.URL + '/api/Admin/'+params.adminid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 관제사 등록
        insert: ({commit}, params) => {
            console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/Admin', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 관제사 수정
        update: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/Admin/'+params.adminid, params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 관제사 삭제
        delete: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.delete(SERVER.URL + '/api/Admin/'+params.adminid,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // 비밀번호 변경
        updatePw: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.put(SERVER.URL + '/api/Admin/UpdatePassword', params, 
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default adminStore
