import axios from "axios";
import SERVER from '@/API/url'

const deviceSettingStore = {
    namespaced: true,
    state: {
        
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        // HumanPresenceRadar 속성 변경
        humanPresenceRadar: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/DeviceManagement/SendHumanPresenceRadarProperty/'+params.deviceid+'/'+params.scene, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // FallDetectionRadar 속성 변경
        fallDetectionRadar: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/DeviceManagement/SendFallDetectionRadarProperty/'+params.deviceid+'/'+params.scene, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // Gateway 속성 변경
        gateway: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/DeviceManagement/SendGatewayProperty/'+params.deviceid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

        // Smartplug 속성 변경
        smartplug: ({commit}, params) => {
            //console.log(params);
            return new Promise((resolve, reject) => {
                axios.post(SERVER.URL + '/api/DeviceManagement/SendSmartplugProperty/'+params.deviceid, params,
                { headers: SERVER.HEADERS }
                ).then(res => {
                    //console.log(res);
                    resolve(res);
                })
                .catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            })
        },

    }
}

export default deviceSettingStore